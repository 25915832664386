import React from "react"
import {graphql} from "gatsby"
import Blog from "../templates/pages/blog";

export default ({data}) => <Blog data={data} isEn={false} />
export const pageQuery = graphql`
    query Blog {
        blog: allMarkdownRemark {
            posts: nodes {
                frontmatter {
                    title
                    date
                    slug
                    author
                    excerpt
                    tag
                }
                fields {
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 2400) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                html
            }
        }
    }
`
