import React from "react"
import {Link, withPrefix, graphql} from "gatsby"
import {formatDistanceToNow} from "date-fns"
import {pl} from "date-fns/locale"
import Layout from "@layout/layout"
import HeaderSubpage from "@layout/header-subpage"
import SEO from "@layout/seo"
import Mosaic from "@blog/mosaic"
import BlogNav from "@blog/nav"
import BackgroundImage from 'gatsby-background-image'

export default ({data, isEn}) => {
  const {posts} = data.blog

  posts.sort(function (a, b) {
    return new Date(b.frontmatter.date) - new Date(a.frontmatter.date)
  })

  return (
    <Layout isEn={isEn}>
      <HeaderSubpage isEn={isEn}/>
      <SEO
        isEn={isEn}
        title={isEn ? 'Blog - Medical support in africa | Foundation SASA' : 'Blog - Opieka medyczna w Afryce | Fundacja SASA'}
        description={isEn ? 'Articles about medical support around the world, SASA helps people in need in Tanzania' : 'Fundacja zbiera pieniądze i sprzęt, by później pomagać potrzebującym placówkom na całym świecie. Chcemy rozwijać swoje umiejętności i poszerzać swoją medyczną wiedzę.' }
      />
      <section className="section wrapper">
        <Mosaic posts={posts}/>
        <BlogNav isEn={isEn}/>
        <div className="row" data-equalizer="news-meta">
          {posts.map((post, key) => (
            <div
              id="entries"
              className="large-4 medium-6 small-12 columns"
              key={key}
            >
              <div className="text-center large-text-left">
                <article className="news-small only-image margin-bottom10">
                  <Link to={post.frontmatter.slug} className="all"/>
                  <BackgroundImage
                    Tag="div"
                    className={"image"}
                    fluid={post.fields.thumbnail.childImageSharp.fluid}
                    style={{position: 'absolute'}}
                  />
                </article>
                <div
                  className="news-meta margin-bottom40"
                  data-equalizer-watch="news-meta"
                >
                  <h6 className="margin-bottom10">
                    <Link
                      to={post.frontmatter.slug}
                      className="black display-block"
                      dangerouslySetInnerHTML={{
                        __html: post.frontmatter.title,
                      }}
                    />
                  </h6>
                  <p className="grey small">
                    <i className="fa fa-clock-o"/>
                    {formatDistanceToNow(new Date(post.frontmatter.date), {
                      locale: pl,
                    })}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section
        className="section padding wow fadeInUp"
        data-wow-duration="1.5s"
        style={{
          visibility: "hidden",
        }}
      >
        <div className="row">
          <div className="large-12 medium-12 small-12 columns">
            <h2 className="text-center large-text-left">{isEn ? 'Newest videos' : 'Ostatnie nagrania'}</h2>
          </div>
        </div>
        <div className="row">
          <div className="large-12 medium-12 small-12 columns">
            <div
              className="movie-box"
              data-equalizer="movie-box"
              data-resize="movie-box"
              data-mutate="movie-box"
              data-events="mutate"
            >
              <div className="row">
                <div className="large-9 medium-12 small-12 columns">
                  <div
                    className="flex-video widescreen margin-0"
                    data-equalizer-watch="movie-box"
                    style={{height: "464px"}}
                  >
                    <iframe
                      id="video"
                      src="https://www.youtube.com/embed/SUTbPBh5pxs"
                      frameBorder={0}
                      allowFullScreen
                    />
                  </div>
                </div>
                <div className="large-3 medium-12 small-12 columns">
                  <div
                    className="movie-list display-table small-block"
                    data-equalizer-watch="movie-box"
                    style={{height: "464px"}}
                  >
                    <div className="display-table-cell small-block">
                      <ul id="videos">
                        <li>
                          <a href="https://www.youtube.com/embed/vW9m5Rac1JY">
                            WOLONTARIAT W TANZANII | #tanSASAnia
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/embed/SUTbPBh5pxs">
                            #tansasania2017 zbiórka polakpotrafi.pl
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/embed/0aWlYyUnFjk">
                            Kartki Świąteczne SASA x Ironteflon
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/embed/8PwxjE56qTw">
                            Białe Niedziele Jedwabno
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/embed/pJr-dT36b_U">
                            #sasabox2 pomagam.pl
                          </a>
                        </li>
                        <li>
                          <a href="https://www.youtube.com/embed/31r4OZz3wXo">
                            Pierwsze promo video Fundacji
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
    query EnMyQuery {
        blog: allMarkdownRemark {
            posts: nodes {
                frontmatter {
                    title
                    date
                    slug
                    author
                    excerpt
                    tag
                }
                fields {
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 2400) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                html
            }
        }
    }
`
